export enum enSubscriptionStatus {
  PENDING = 'pending',
  WAITING_PAYMENT = 'waiting_payment',
  PAID = 'paid',
  EXPIRED = 'expired',
  CANCELED = 'canceled',
  LATE = 'late',
  PAUSED = 'paused',
  OVERDUE = 'overdue',
  FINISHED = 'finished',
}

export const subscriptionStatusNameByStatus = {
  [enSubscriptionStatus.PENDING]: 'Pendente',
  [enSubscriptionStatus.WAITING_PAYMENT]: 'Aguardando pagamento',
  [enSubscriptionStatus.PAID]: 'Em dia',
  [enSubscriptionStatus.EXPIRED]: 'Expirado',
  [enSubscriptionStatus.CANCELED]: 'Cancelado',
  [enSubscriptionStatus.LATE]: 'Atrasado',
  [enSubscriptionStatus.PAUSED]: 'Pausado',
  [enSubscriptionStatus.OVERDUE]: 'Inadimplente',
  [enSubscriptionStatus.FINISHED]: 'Finalizado',
} as {
  [key in enSubscriptionStatus]: string;
};

export const subscriptionColorByStatus = {
  [enSubscriptionStatus.PENDING]: 'blue',
  [enSubscriptionStatus.WAITING_PAYMENT]: 'orange',
  [enSubscriptionStatus.PAID]: 'green',
  [enSubscriptionStatus.EXPIRED]: 'red',
  [enSubscriptionStatus.CANCELED]: 'gray',
  [enSubscriptionStatus.LATE]: 'red',
  [enSubscriptionStatus.PAUSED]: 'gray',
  [enSubscriptionStatus.OVERDUE]: 'red',
  [enSubscriptionStatus.FINISHED]: 'green',
} as {
  [key in enSubscriptionStatus]: string;
};
