import { memo, useCallback, useEffect, useState } from 'react';
import { IStyledProp } from '../../interfaces/IStyledProp';
import {
  Alert,
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Form,
  FormInstance,
  FormListFieldData,
  Layout,
  Modal,
  Row,
  Space,
  Spin,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import {
  BarcodeOutlined,
  CloseCircleOutlined,
  CreditCardOutlined,
  EditOutlined,
  FileSyncOutlined,
  QrcodeOutlined,
} from '@ant-design/icons';
import { buildPayments, getCdnUrl, getFrequency, moneyMask } from '../../components/Form/helper';
import { enPaymentMethod } from '../../enums/enPaymentMethod';
import { ISubscription, ISubscriptionItem, ISubscriptionPaymentMethod } from '../../interfaces/ISubscription';
import { subscriptionColorByStatus, subscriptionStatusNameByStatus } from '../../enums/enSubscriptionStatus';
import CreditCard from '../../components/Form/Payment/CreditCard';
import { useMutation, useQuery } from '@tanstack/react-query';
import { checkoutService } from '../../services/CheckoutService';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { formatPhone } from '../../helpers/functions';
import logo from '../../assets/logo1.png';
import { useAppContext } from '../../context';
import { captureException } from '@sentry/react';
import Lottie from 'react-lottie';
import successAnimation from '../../assets/lotties/success.json';

interface ISubscriptionProps extends IStyledProp {}

const { Text } = Typography;

const paymentNameByType = {
  [enPaymentMethod.CREDIT_CARD]: 'Cartão de Crédito',
  [enPaymentMethod.PIX]: 'Pix',
  [enPaymentMethod.BOLETO]: 'Boleto',
} as { [key: string]: string };

const paymentIconByType = {
  credit_card: <CreditCardOutlined style={{ fontSize: 16, marginRight: 5 }} />,
  pix: <QrcodeOutlined style={{ fontSize: 16, marginRight: 5 }} />,
  boleto: <BarcodeOutlined style={{ fontSize: 16, marginRight: 5 }} />,
} as { [key: string]: JSX.Element };

const PaymentMethod = memo<{
  subscription: ISubscription;
  name: (number | string)[];
  field: FormListFieldData;
  form: FormInstance;
}>(({ form, field, name, subscription: { items } }) => {
  const total = items.reduce((acc, item) => acc + item.amount, 0);
  const payment = Form.useWatch(name, form) as ISubscriptionPaymentMethod & { isEditing: boolean; isNew: boolean };
  const [oldPaymentMethod, setOldPaymentMethod] = useState<string>(payment?.paymentMethod);
  const availablePaymentMethods = [enPaymentMethod.CREDIT_CARD, enPaymentMethod.PIX, enPaymentMethod.BOLETO];

  const canShowCreditCard = availablePaymentMethods.includes(enPaymentMethod.CREDIT_CARD);
  const canShowPix = availablePaymentMethods.includes(enPaymentMethod.PIX);
  const canShowBoleto = availablePaymentMethods.includes(enPaymentMethod.BOLETO);
  const hasOnlyOnePaymentMethodShown = [canShowBoleto, canShowPix, canShowCreditCard].filter(Boolean).length === 1;

  useEffect(() => {
    if (oldPaymentMethod) {
      return;
    }

    setOldPaymentMethod(payment?.paymentMethod);
  }, [payment?.paymentMethod]);

  if (!payment) {
    return null;
  }

  const setAsEditing = () => {
    form.setFieldValue([...name, 'isEditing'], !payment.isEditing);
  };

  const changePaymentMethod = (method: string) => {
    form.setFieldValue([...name, 'paymentMethod'], method);
  };

  const hasChangingPaymentMethod = oldPaymentMethod !== payment?.paymentMethod && !payment?.isNew;

  return (
    <Col span={24}>
      <Card>
        <Row>
          <Col span={!payment.isEditing ? 20 : 24}>
            <Row>
              <Col span={payment.isEditing ? 20 : 24}>
                <Space>
                  <Text className="font-bold text-gray-500">
                    {paymentIconByType[payment.paymentMethod]} {paymentNameByType[payment.paymentMethod]}
                  </Text>
                  <Text className="text-sm text-gray-500">
                    R$ {moneyMask.apply((total * (payment.percentageValue / 100)) / 100)}
                  </Text>
                </Space>
              </Col>

              {payment.isEditing && (
                <Col span={4} className="flex justify-end">
                  <Tooltip title="Cancelar edição">
                    <Typography.Link onClick={setAsEditing}>
                      <CloseCircleOutlined style={{ fontSize: 16, marginRight: 5 }} />
                    </Typography.Link>
                  </Tooltip>
                </Col>
              )}

              {payment.paymentMethod == enPaymentMethod.CREDIT_CARD && !payment.isEditing && (
                <Col span={24}>
                  <Space>
                    <Text className="text-sm text-gray-500">
                      {payment.cardInfo?.brand} {payment.cardInfo?.firstDigits} **** **** {payment.cardInfo?.lastDigits}
                    </Text>
                  </Space>
                </Col>
              )}
            </Row>
            {payment.isEditing && (
              <Row className="mt-5">
                <Col span={24}>
                  <Form.Item
                    {...field}
                    name={[field.name, 'paymentMethod']}
                    rules={[{ required: true, message: 'Selecione um tipo de pagamento' }]}
                    hidden={hasOnlyOnePaymentMethodShown}
                  >
                    <Row gutter={10} className="flex items-center justify-center">
                      {canShowCreditCard && (
                        <Col span={8} className="flex items-center justify-center">
                          <Button
                            className="rounded-md"
                            type={payment.paymentMethod === 'credit_card' ? 'primary' : 'default'}
                            icon={<CreditCardOutlined />}
                            size="large"
                            block
                            onClick={() => changePaymentMethod('credit_card')}
                          >
                            Cartão
                          </Button>
                        </Col>
                      )}
                      {canShowPix && (
                        <Col span={8} className="flex items-center justify-center">
                          <Button
                            className="rounded-md"
                            type={payment.paymentMethod === 'pix' ? 'primary' : 'default'}
                            icon={<QrcodeOutlined />}
                            size="large"
                            block
                            onClick={() => changePaymentMethod('pix')}
                          >
                            PIX
                          </Button>
                        </Col>
                      )}
                      {canShowBoleto && (
                        <Col span={8} className="flex items-center justify-center">
                          <Button
                            className="rounded-md"
                            type={payment.paymentMethod === 'boleto' ? 'primary' : 'default'}
                            icon={<BarcodeOutlined />}
                            size="large"
                            block
                            onClick={() => changePaymentMethod('boleto')}
                          >
                            Boleto
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </Form.Item>
                </Col>
                {hasChangingPaymentMethod && (
                  <Col span={24} className="mb-5">
                    <Alert
                      message="Atenção"
                      description={`Você está alterando a forma de pagamento de ${paymentNameByType[oldPaymentMethod]} para ${paymentNameByType[payment.paymentMethod]}.`}
                      type="warning"
                      showIcon
                    />
                  </Col>
                )}
                <Col span={24}>
                  {payment.paymentMethod == enPaymentMethod.CREDIT_CARD && (
                    <CreditCard form={form} name={[field.name, 'creditCard']} disableInstallments basePath="payments" />
                  )}
                </Col>
              </Row>
            )}
          </Col>
          {!payment.isEditing && (
            <Col span={4} className="flex justify-end">
              <Tooltip title="Editar forma de pagamento">
                <Typography.Link onClick={setAsEditing}>
                  <EditOutlined style={{ fontSize: 16, marginRight: 5 }} />
                </Typography.Link>
              </Tooltip>
            </Col>
          )}
        </Row>
      </Card>
    </Col>
  );
});

const SubscriptionItem = memo<{ item: ISubscriptionItem; subscription: ISubscription }>(
  ({ item, subscription: data }) => {
    return (
      <Row key={item.id} className="flex items-center" gutter={[16, 16]}>
        <Col span={24}>
          <Row>
            <Text className="font-bold text-gray-500">{item.name}</Text>
          </Row>
          <Row>
            <Text className="text-sm text-gray-500 mt-2">
              <span className="text-lg font-bold">R$ {moneyMask.apply(item.amount / 100)}</span>
              <div className="text-xs text-gray-500">
                a cada {data?.interval} {getFrequency(data?.frequency, data?.interval)}{' '}
                {data.totalChargesQuantity && data.totalChargesQuantity > 1 && (
                  <>
                    durante o período de {data.interval * data.totalChargesQuantity}{' '}
                    {getFrequency(data?.frequency, data.interval * data.totalChargesQuantity)}
                  </>
                )}
              </div>
            </Text>
          </Row>
        </Col>
      </Row>
    );
  }
);

const Subscription = memo<ISubscriptionProps>(({ className }) => {
  const { id } = useParams();

  const { setStyle } = useAppContext();
  const [form] = Form.useForm();
  const [modalError, setModalError] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const paymentMethods =
    (Form.useWatch('payments', form) as (ISubscriptionPaymentMethod & {
      isEditing: boolean;
      isNew: boolean;
    })[]) ?? [];

  const { isLoading, data, error, isError, refetch, isFetching } = useQuery({
    queryKey: ['subscription', id],
    queryFn: async () => {
      if (!id) {
        return undefined;
      }

      return checkoutService.mountAsSubscription(id);
    },
    enabled: !!id,
    retry: 0,
  });

  const buildInitialValues = () => {
    if (!data) {
      return {};
    }

    const total = data.items.reduce((acc, item) => acc + item.amount, 0) / 100;

    return {
      payments: data.paymentMethods.map((payment) => ({
        ...payment,
        value: total * (payment.percentageValue / 100),
        isEditing: false,
        isNew: false,
      })),
    };
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    setStyle(data.styleConfig);
  }, [setStyle, data]);

  const { mutate, isPending } = useMutation({
    mutationFn: async (body: any) => {
      if (!data) {
        return;
      }

      const payments = await buildPayments(data.teamId, {
        payments: body.payments,
        customer: data.customer,
      });

      const hasInvalidPayment = payments.some((payment) => !payment);

      if (hasInvalidPayment) {
        captureException(new Error('Invalid payment'), { extra: { data, body } });
        setModalError(true);
        return;
      }

      const payload = {
        id: data.id,
        payments,
      };

      return checkoutService.changeSubscriptionPaymentMethods(payload);
    },
    onSuccess: async (result) => {
      if (!result) {
        return;
      }

      if (['changed'].includes(result.status)) {
        setModalSuccess(true);
        return;
      }

      captureException(new Error('Error on change payment method'), { extra: { result, data } });

      setModalError(true);
    },
    onError: (err, variables) => {
      captureException(err, { extra: { data, variables } });
      console.error(err);
      setModalError(true);
    },
  });

  const handleSuccessOk = async () => {
    setModalSuccess(false);
    await refetch();

    //should reset form
    form.setFieldsValue(buildInitialValues());
  };

  const onFinish = useCallback(
    (values: any) => {
      mutate(values);
    },
    [mutate]
  );

  const hasAnyEditingOrNewPayment = paymentMethods.some((payment) => payment.isEditing || payment.isNew);

  if (isError && axios.isAxiosError(error)) {
    const code = error.response?.data?.code;

    if (code == 'NOT_FOUND') {
      return (
        <Alert
          message={
            <span>
              <div className="flex items-center justify-center my-4">
                <img width={150} className="" src={getCdnUrl(data?.companyLogo, logo)}></img>
              </div>
              <p className="font-bold mb-3">Assinatura não encontrada!</p>
              <p className="mb-1">Geralmente isso ocorre quando o link da assinatura está incorreto.</p>
              {data?.companyEmail && data?.companyPhone && (
                <p className="mb-5">
                  Para mais informações entre em contato com <strong>{data?.companyEmail}</strong> ou no telefone{' '}
                  <strong>{formatPhone(data?.companyPhone)}</strong>
                </p>
              )}
            </span>
          }
          type="error"
          showIcon
        />
      );
    }

    return <Alert message="Erro ao carregar a assinatura" type="error" showIcon />;
  }

  if (isLoading || isFetching || !data) {
    return <Spin tip="Carregando..." fullscreen size="large"></Spin>;
  }

  return (
    <div
      className={className}
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#EBEFF4' }}
    >
      <Modal
        closable={false}
        open={modalError}
        title="Não foi possível concluir sua compra!"
        footer={[
          <Button className="rounded-md w-20" type="default" size="large" onClick={() => setModalError(false)}>
            OK
          </Button>,
        ]}
      >
        <p className="my-3">Abaixo alguns possíveis motivos...</p>

        <Collapse
          collapsible="header"
          defaultActiveKey={['1']}
          ghost
          items={[
            {
              key: '1',
              label: <p className="font-bold">Dados incorretos</p>,
              children: (
                <p>
                  Acontece quando as informações do cartão não foram preenchidas corretamente. Verifique desde o nome,
                  passando pelo número do cartão, data de validade até o código de segurança.
                </p>
              ),
            },
          ]}
        />
        <Collapse
          collapsible="header"
          ghost
          items={[
            {
              key: '2',
              label: <p className="font-bold">Limite insuficiente</p>,
              children: (
                <p>
                  Isso ocorre quando o cartão selecionado não possui limite suficiente para que a compra seja feita.
                  Confira com a operadora do cartão se é possível aumentar o limite ou utilize outra forma de pagamento.
                </p>
              ),
            },
          ]}
        />
        <Collapse
          collapsible="header"
          ghost
          items={[
            {
              key: '3',
              label: <p className="font-bold">Compra suspeita</p>,
              children: (
                <p>
                  Acontece em alguns casos quando a própria operadora do cartão classifica a compra como uma possível
                  fraude. Costuma ocorrer quando uma compra fora do padrão, como por exemplo, uma transação de valor
                  muito alto.
                </p>
              ),
            },
          ]}
        />

        {data?.companyEmail && data?.companyPhone && (
          <p className="mt-5">
            Para mais informações entre em contato com <strong>{data?.companyEmail}</strong> ou no telefone{' '}
            <strong>{formatPhone(data?.companyPhone)}</strong>
          </p>
        )}
      </Modal>

      <Modal
        closable={false}
        open={modalSuccess}
        title="Forma de pagamento alterada com sucesso!"
        footer={[
          <Button className="rounded-md w-20" type="default" size="large" onClick={handleSuccessOk}>
            OK
          </Button>,
        ]}
      >
        <Row justify="center" className={className}>
          <Col span={24}>
            <Lottie
              options={{
                loop: 0,
                autoplay: true,
                animationData: successAnimation,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              width={160}
            />
          </Col>
          <Col span={24} className="messages">
            <Typography.Text className="text-lg text-gray-500 font-bold">
              As próximas cobranças serão feitas com a nova forma de pagamento.
            </Typography.Text>
          </Col>
        </Row>
      </Modal>

      <Layout className="bg-gray-100" style={{ minWidth: 380, maxWidth: 620, margin: 10, backgroundColor: '#fff' }}>
        <Row className="bg-green-900 rounded-t-lg h-16 items-center px-3">
          <Col>
            <FileSyncOutlined style={{ color: '#ffffff', fontSize: 26 }} />
            <Text className="pl-2 text-2xl text-white">Assinatura #{data.code}</Text>
          </Col>
        </Row>

        {data.isTest && (
          <Row className="mb-1 mt-1">
            <Col span={24} className="flex justify-center">
              <Space direction="vertical" style={{ width: '100%' }}>
                <Alert
                  message="Ambiente de Teste"
                  description="Esta é uma simulação de compra, nenhum valor será cobrado."
                  type="warning"
                  showIcon
                />
              </Space>
            </Col>
          </Row>
        )}

        <Row gutter={[16, 16]} className="p-5">
          <Col span={24}>
            <Space direction="vertical">
              <Typography.Title level={5} className="text-lg space-y-2">
                <span className="mr-4">DETALHES DA ASSINATURA</span>
                <Tag color={subscriptionColorByStatus[data.status]}>{subscriptionStatusNameByStatus[data.status]}</Tag>
              </Typography.Title>
              {data.items.map((item) => (
                <SubscriptionItem key={item.id} item={item} subscription={data} />
              ))}
            </Space>
          </Col>

          <Col span={24}>
            <Divider />
          </Col>

          <Col span={24}>
            <Typography.Title level={5} className="text-lg">
              FORMAS DE PAGAMENTO
            </Typography.Title>

            <Form
              layout="vertical"
              initialValues={buildInitialValues()}
              onFinish={onFinish}
              scrollToFirstError
              form={form}
            >
              <Row gutter={[16, 16]}>
                <Form.List name="payments">
                  {(fields) => (
                    <>
                      {fields.map((field) => (
                        <PaymentMethod
                          form={form}
                          key={field.key}
                          name={['payments', field.name]}
                          field={field}
                          subscription={data}
                        />
                      ))}
                    </>
                  )}
                </Form.List>

                <Col span={24}>
                  <Form.Item>
                    <Button
                      type="primary"
                      size="large"
                      htmlType="submit"
                      block
                      className="rounded-md"
                      disabled={!hasAnyEditingOrNewPayment || isPending}
                      loading={isPending}
                    >
                      Alterar Forma de Pagamento
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Layout>
    </div>
  );
});

export default Subscription;
