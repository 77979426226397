import { captureException } from '@sentry/react';
import { CDN_URL } from '../../settings';

export const moneyMask = {
  apply: (value: number | string | undefined | null) => {
    if (value === null || value === undefined || value === '') return '';

    const cleaned = moneyMask.clean(value);
    return new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(cleaned || 0);
  },

  clean: (value: number | string) => {
    if (typeof value === 'number') return value;

    value = (value || '').toString().replace(/[^\d,]/gi, '');

    /*if (!value.includes(',')) {
      value = `0,${value}`.padStart(2, '0');
    }*/

    const [, cents] = value.split(',');

    if (cents && cents.length !== 2) {
      value = value
        .replace(',', '')
        .replace(/(\d+)?(\d{2})/gi, '$1,$2')
        .replace(/^,/gi, '0,');
    }

    return parseFloat(value.replace(/\./gi, '').replace(',', '.'));
  },
};

export const dateMask = {
  apply: (value: string) => {
    if (!value) return '';

    if (value.length === 7) {
      const [month, year] = value.split('/');
      if (year.length === 4) {
        value = `${month}/${year.slice(2)}`;
      }
    }

    // format to MM/YY
    const result = value
      .replace(/\D/g, '')
      .slice(0, 4)
      .replace(/(\d{2})\//gi, '$1')
      .replace(/(\d{2})(\d{1})/gi, '$1/$2')
      .replace(/(\d{2})(\d{2})/gi, '$1/$2')
      .slice(0, 5);

    return result;
  },

  clean: (value: string) => {
    if (!value) return value;
    return value.replace(/\D/g, '').slice(0, 4);
  },
};

export const creditCardMask = {
  apply: (value: string) => {
    if (!value) return '';
    value = value.replace(/\D/g, '');

    return value
      .slice(0, 16)
      .replace(/(\d{4})/gi, '$1 ')
      .trim();
  },

  clean: (value: string) => {
    if (!value) return value;
    return value.replace(/\D/g, '').slice(0, 16);
  },
};

export const cepMask = {
  apply: (value: string) => {
    if (!value) return '';
    value = value.replace(/\D/g, '');

    return value
      .slice(0, 8)
      .replace(/(\d{5})(\d{3})/gi, '$1-$2')
      .trim();
  },

  clean: (value: string) => {
    if (!value) return value;
    return value.replace(/\D/g, '').slice(0, 8);
  },
};

export const phoneMask = {
  apply: (value: string) => {
    if (!value) return value;
    value = value.replace(/\D/g, '');

    if (value.length < 8) {
      return value.replace(/(\d{2})(\d{1,4})/gi, '($1) $2');
    }

    return value
      .slice(0, 11)
      .replace(/(\d{2})(\d{5})(\d{1,4})/gi, '($1) $2-$3')
      .trim();
  },
  clean: (value: string) => {
    if (!value) return value;

    return value.replace(/\D/gi, '').substring(0, 20);
  },
};

export const documentMask = {
  apply: (value: string) => {
    if (!value) return '';
    value = value.replace(/\D/g, '');

    if (value.length > 11) {
      return value
        .slice(0, 14)
        .replace(/(\d{2})(\d{3})(\d{3})(\d)/, '$1.$2.$3/$4')
        .replace(/(\d{2}).(\d{3}).(\d{3})\/(\d{4})(\d)/, '$1.$2.$3/$4-$5')
        .replace(/(\d{2}).(\d{3}).(\d{3})\/(\d{4})-(\d{2})/gi, '$1.$2.$3/$4-$5')
        .trim();
    }

    return value
      .slice(0, 11)
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3}).(\d{3})(\d)/, '$1.$2.$3')
      .replace(/(\d{3}).(\d{3}).(\d{3})/, '$1.$2.$3')
      .replace(/(\d{3}).(\d{3}).(\d{3})(\d)/, '$1.$2.$3-$4')
      .replace(/(\d{3}).(\d{3}).(\d{3}).(\d{2})/gi, '$1.$2.$3-$4')
      .trim();
  },

  clean: (value: string) => {
    if (!value) return value;
    return value.replace(/\D/g, '').slice(0, 14);
  },
};

export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const buildPayments = async (teamId: string, data: any) => {
  const payments: any[] = [];
  let index = 0;

  for (const pay of data.payments) {
    try {
      if (pay.paymentMethod === 'pix' || pay.paymentMethod === 'boleto') {
        payments.push({
          paymentMethod: pay.paymentMethod,
          value: Math.round(moneyMask.clean(pay.value) * 100),
        });

        continue;
      }

      if (pay.creditCard?.id) {
        payments.push({
          paymentMethod: pay.paymentMethod,
          value: Math.round(moneyMask.clean(pay.value) * 100),
          creditCard: {
            id: pay.creditCard.id,
            installments: pay.creditCard.installments ?? 1,
            installmentValue: pay.creditCard.installmentValue,
            installmentHash: pay.creditCard.installmentHash,
          },
        });

        continue;
      }

      await delay(index++ * 2000);

      const expiration = pay.creditCard.expiration.split('/');

      const token = await window.Routerfy.tokenizer.generateToken({
        key: `team_${teamId}`,
        card: {
          number: creditCardMask.clean(pay.creditCard.number),
          holder: pay.creditCard.holder,
          document: documentMask.clean(data.customer.document),
          cvv: pay.creditCard.cvv,
          expiration: `${expiration[0].padStart(2, '0')}/20${expiration[1].padStart(2, '0')}`,
        },
      });

      payments.push({
        paymentMethod: pay.paymentMethod,
        value: Math.round(moneyMask.clean(pay.value) * 100),
        creditCard: {
          token,
          installments: pay.creditCard.installments ?? 1,
          installmentValue: pay.creditCard.installmentValue,
          installmentHash: pay.creditCard.installmentHash,
        },
      });
    } catch (error) {
      captureException(error, { extra: { data } });
      console.error(error);
    }
  }

  return payments;
};

export const buildCustomer = (data: any, isForeigner: boolean) => {
  if (data.customer.id) {
    return {
      id: data.customer.id,
    };
  }

  const { ddi, ...rest } = data.customer;
  const phoneNumber = phoneMask.clean(data.customer.phone);
  const ddiNumber = ddi.replace(/\D/g, '');
  const phone = `${ddiNumber}${phoneNumber}`;

  if (isForeigner) {
    return {
      ...rest,
      phone,
      type: 'foreign',
    };
  }

  const document = documentMask.clean(data.customer.document);
  const type = document.length > 11 ? 'company' : 'individual';

  const address = data.customer.address
    ? {
        ...data.customer.address,
        postalCode: cepMask.clean(data.customer.address.postalCode),
        country: 'BR',
      }
    : {};

  return {
    ...rest,
    ...(address ? { address } : {}),
    document,
    phone,
    type,
  };
};

export const getCdnUrl = (path?: string, defaultValue?: string) => {
  if (!path) return defaultValue;

  if (path.startsWith('http') || path.startsWith('blob')) return path;

  return `${CDN_URL}/${path}`;
};

export const maskString = (value: string, startCount = 4, endCount = 4) => {
  const start = value.slice(0, startCount);
  const end = value.slice(-endCount);

  return `${start}${'*'.repeat(value.length - startCount - endCount)}${end}`;
};

export const buildOCBData = (data: any) => {
  const payments = data.payments
    .map((payment: any) => {
      if (payment.paymentMethod !== 'credit_card') return;

      return payment.response?.card;
    })
    .filter((card: any) => !!card);

  const customer = {
    id: data.customer.id,
    name: data.customer.name,
    email: maskString(data.customer.email),
    document: maskString(data.customer.document),
    phone: maskString(data.customer.phone),
  };

  return btoa(JSON.stringify({ customer, payments }));
};

export const checkLuhn = (cardNumber: string) => {
  cardNumber = cardNumber.replace(/\D/g, '');
  let sum = 0;
  let shouldDouble = false;

  for (let i = cardNumber.length - 1; i >= 0; i--) {
    let digit = parseInt(cardNumber.charAt(i));

    if (shouldDouble) {
      digit *= 2;
      if (digit > 9) {
        digit -= 9;
      }
    }

    sum += digit;
    shouldDouble = !shouldDouble;
  }

  return sum % 10 === 0;
};

const frenquencies = {
  day: 'dia',
  week: 'semana',
  month: 'mês',
  year: 'ano',
} as {
  [key: string]: string;
};

const frenquenciesPlural = {
  day: 'dias',
  week: 'semanas',
  month: 'meses',
  year: 'anos',
} as {
  [key: string]: string;
};

export const getFrequency = (frequency: string, interval: number) => {
  return interval > 1 ? frenquenciesPlural[frequency] : frenquencies[frequency];
};
