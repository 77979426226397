import { memo, useCallback, useEffect, useState } from 'react';
import { IStyledProp } from '../../interfaces/IStyledProp';
import FormProvider from './context';
import { useMutation, useQuery } from '@tanstack/react-query';
import { checkoutService } from '../../services/CheckoutService';
import {
  Col,
  Form as FormAntd,
  Modal,
  Row,
  Spin,
  Button,
  Divider,
  Checkbox,
  Typography,
  Alert,
  Collapse,
  CheckboxProps,
} from 'antd';
import BasicInfo from './Customer/BasicInfo';
import Address from './Customer/Address';
import Payment from './Payment';
import axios from 'axios';
import { buildCustomer, buildPayments, moneyMask, getCdnUrl, buildOCBData } from './helper';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ICheckout } from '../../interfaces/ICheckout';
import LanguageSelector from './LanguageSelector';
import { usePreview } from '../../hooks/usePreview';
import TestAlert from '../TestAlert';
import Header from './Header';
import Footer from './Footer';
import { ShoppingCartOutlined } from '@ant-design/icons';
import OrderBump from './OrderBump';
import Terms from '../Terms';
import logo from '../../assets/logo1.png';
import { trackService } from '../../services/TrackService';
import { formatPhone, setupGoogleTagManager } from '../../helpers/functions';
import { socketService } from '../../services/SocketService';
import { useAppContext } from '../../context';
import BasicInfoResumed from './Customer/BasicInfoResumed';
import PayingModal from './PayingModal';
import { captureException } from '@sentry/react';

export interface IProps extends IStyledProp {
  id: string;
  modeAs: 'checkout' | 'transaction' | 'preview';
}

const { Link } = Typography;

const Form = memo<IProps>(({ className, modeAs, id }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [items, setItems] = useState<ICheckout['items']>([]);
  const [modalError, setModalError] = useState(false);
  const [cachedData, setCachedData] = useState<ICheckout>();
  const [ocbData, setOcbData] = useState<any>();
  const [isForeigner, setIsForeigner] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState<string[]>([]);

  const [enablePay, setEnablePay] = useState(false);

  const [isSaveOCB, setSaveOCB] = useState(true);

  const { setStyle } = useAppContext();

  const onChange: CheckboxProps['onChange'] = (e) => {
    setEnablePay(e.target.checked);
  };

  const onChangeSaveOCB: CheckboxProps['onChange'] = (e) => {
    setSaveOCB(e.target.checked);
  };

  const { prewiewData } = usePreview(id, modeAs);

  const isCached = !!window.__data && modeAs == 'checkout';
  const isOCB = !!ocbData;

  let { isLoading, data, error, isError } = useQuery({
    queryKey: [modeAs, id],
    queryFn: async () => {
      if (modeAs == 'transaction') {
        return checkoutService.mountAsTransaction(id);
      }

      return checkoutService.mountAsCheckout(id);
    },
    enabled: modeAs != 'preview' && !isCached,
    retry: 0,
  });

  data = modeAs == 'preview' ? prewiewData : isCached ? cachedData : data;

  const { mutate, isPending } = useMutation({
    mutationFn: async (body: any) => {
      if (!data) {
        return;
      }

      const payments = await buildPayments(data.teamId, body);
      const hasInvalidPayment = payments.some((payment) => !payment);

      if (hasInvalidPayment) {
        captureException(new Error('Invalid payment'), { extra: { data, body } });
        setModalError(true);
        return;
      }

      const customer = buildCustomer(body, isForeigner);

      const query = Array.from(searchParams.entries()).reduce(
        (acc, [key, value]) => {
          acc[key] = value;
          return acc;
        },
        {} as { [key: string]: string }
      );

      const payload = {
        ...body,
        customer,
        payments,
        teamId: data.teamId,
        id: data.id,
        items,
        query,
        isCached,
        modeAs,
      };

      trackService.trackEvent({
        eventName: 'InitiateCheckout',
        eventTime: Date.now(),
        eventSourceUrl: window.location.href,
        checkoutId: data.id!,
        data: {
          deviceInfo: {
            userAgent: navigator.userAgent,
          },
          customer,
          payments,
          items,
        },
      });

      const hasGtm = !!data.pixels?.find(
        (pixel) => pixel.type == 'google-tag-manager' && pixel.config.events.includes('initiateCheckout')
      );

      if (hasGtm) {
        let arrItems = items.map((item) => {
          return {
            description: item.description,
            code: item.code,
            amount: item.amount && item.amount > 0 ? item.amount / 100 : 0,
            quantity: item.quantity,
            type: item.type,
          };
        });

        let arrPayments = payments.map((payment) => {
          return {
            paymentMethod: payment.paymentMethod,
            value: payment.value && payment.value > 0 ? payment.value / 100 : 0,
          };
        });

        let amount = handleGetTotal();
        amount = amount && amount > 0 ? amount / 100 : 0;

        window.dataLayer.push({
          event: 'InitiateCheckout',
          eventSourceUrl: window.location.href,
          checkoutId: data.id!,
          data: {
            amount: amount,
            deviceInfo: {
              userAgent: navigator.userAgent,
            },
            customer: customer,
            payments: arrPayments,
            items: arrItems,
          },
        });
      }

      return checkoutService.pay(payload);
    },
    onSuccess: (data) => {
      if (!data) {
        return;
      }

      if (['paid', 'waiting_payment', 'partially_paid'].includes(data.status)) {
        if (isSaveOCB) {
          const ocb = buildOCBData(data);
          localStorage.setItem(`ocb-${data.teamId}`, ocb);
        }

        const url = modeAs == 'transaction' ? `/t/${data.id}/success` : `/c/${data.checkoutId}/success/${data.id}`;
        navigate(url, {
          state: { data },
        });
        return;
      }

      captureException(new Error('Error on pay'), { extra: { data } });

      setModalError(true);
    },
    onError: (err, variables) => {
      captureException(err, { extra: { data, variables } });
      console.error(err);
      setModalError(true);
    },
  });

  const [form] = FormAntd.useForm();

  const customer = FormAntd.useWatch(['customer'], form);

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (!isCached) {
      return;
    }

    const data = JSON.parse(decodeURIComponent(window.__data));
    setCachedData(data);
  }, [isCached]);

  useEffect(() => {
    if (modeAs == 'preview') {
      return;
    }

    if (!data?.pixels) {
      return;
    }

    //retornar o primeiro pixel do tipo GTM do data.pixels
    const gtmPixel = data.pixels?.find((pixel) => pixel.type == 'google-tag-manager');

    if (gtmPixel) {
      if (!setupGoogleTagManager(gtmPixel.config.id!)) {
        //remover o gtmPixel encontrado do array de pixels
        data.pixels = data.pixels.filter((pixel) => pixel.type != 'google-tag-manager');
      }
    }
  }, [data]);

  useEffect(() => {
    if (modeAs == 'preview') {
      return;
    }

    if (!data || !items.length) {
      return;
    }

    trackService.trackEvent({
      eventName: 'PageView',
      eventTime: Date.now(),
      eventSourceUrl: window.location.href,
      checkoutId: data.id!,
      data: {
        deviceInfo: {
          userAgent: navigator.userAgent,
        },
        items: items,
      },
    });

    const hasGtm = !!data.pixels?.find(
      (pixel) => pixel.type == 'google-tag-manager' && pixel.config.events.includes('pageView')
    );

    if (hasGtm) {
      let arrItems = items.map((item) => {
        return {
          description: item.description,
          code: item.code,
          amount: item.amount && item.amount > 0 ? item.amount / 100 : 0,
          quantity: item.quantity,
          type: item.type,
        };
      });

      window.dataLayer.push({
        event: 'PageView',
        eventSourceUrl: window.location.href,
        checkoutId: data.id!,
        data: {
          deviceInfo: {
            userAgent: navigator.userAgent,
          },
          items: arrItems,
        },
      });
    }
  }, [data, items]);

  useEffect(() => {
    if (modeAs == 'preview') {
      return;
    }

    if (!customer || !data) {
      return;
    }

    const execute = async () => {
      if (!data) {
        return;
      }

      trackService.trackEvent({
        eventName: 'Lead',
        eventTime: Date.now(),
        eventSourceUrl: window.location.href,
        checkoutId: data.id!,
        data: {
          deviceInfo: {
            userAgent: navigator.userAgent,
          },
          customer: customer,
          items: items,
        },
      });

      const hasGtm = !!data.pixels?.find(
        (pixel) => pixel.type == 'google-tag-manager' && pixel.config.events.includes('lead')
      );

      if (hasGtm) {
        let arrItems = items.map((item) => {
          return {
            description: item.description,
            code: item.code,
            amount: item.amount && item.amount > 0 ? item.amount / 100 : 0,
            quantity: item.quantity,
            type: item.type,
          };
        });

        window.dataLayer.push({
          event: 'Lead',
          eventSourceUrl: window.location.href,
          checkoutId: data.id!,
          data: {
            deviceInfo: {
              userAgent: navigator.userAgent,
            },
            customer: customer,
            items: arrItems,
          },
        });
      }
    };

    const timeout = setTimeout(() => {
      execute();
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [customer, data, items]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = useCallback(
    async (body: any) => {
      mutate(body);
    },
    [data, mutate]
  );

  const handleAddItems = useCallback(
    (items: ICheckout['items']) => {
      setItems((prev) => Array.from(new Set([...prev, ...items])));
    },
    [setItems]
  );

  const handleRemoveItems = useCallback((items: ICheckout['items']) => {
    setItems((prev) => prev.filter((item) => !items.includes(item)));
  }, []);

  const handleGetTotal = useCallback(
    (withoutBump?: boolean) => {
      const hasEnrollmentFee = items.some((item) => item.type == 'enrollment_fee');
      const validItems = items.filter(
        (item) => !hasEnrollmentFee || ['enrollment_fee', 'order_bump'].includes(item.type)
      );

      return validItems.reduce((acc, item) => {
        if (withoutBump && item.type == 'order_bump') {
          return acc;
        }

        return acc + item.amount * item.quantity;
      }, 0);
    },
    [items]
  );

  useEffect(() => {
    if (!data) {
      return;
    }

    const newItems = data.items.filter((item) =>
      ['main', 'cart', 'enrollment_fee', 'tax_fee', 'late_fee', 'daily_interest_fee'].includes(item.type)
    );

    const firstItems = newItems.filter((item) => ['main', 'cart'].includes(item.type));

    const firstItem = firstItems[0];
    if (firstItem) {
      document.title = `${firstItem.description} - Checkout`;
    }

    setItems(newItems);
    setStyle(data.styleConfig);
    setPaymentMethods([
      ...(data.allowCreditCard ? ['credit_card'] : []),
      ...(data.allowPix ? ['pix'] : []),
      ...(data.allowBoleto ? ['boleto'] : []),
    ]);

    const ocbData = localStorage.getItem(`ocb-${data.teamId}`);
    if (ocbData) {
      setOcbData(JSON.parse(atob(ocbData)));
    }
  }, [data]);

  useEffect(() => {
    if (!data?.id || !data?.teamId || modeAs == 'transaction') {
      return;
    }

    socketService.connect({
      checkoutId: data.id!,
      teamId: data.teamId,
    });
  }, [data?.id, data?.teamId]);

  const firstPaymentMethod = paymentMethods[0];

  useEffect(() => {
    if (!firstPaymentMethod) {
      return;
    }

    const total = handleGetTotal() / 100.0;
    if (!total) {
      return;
    }

    form.setFieldValue('payments', [
      {
        paymentMethod: firstPaymentMethod,
        value: moneyMask.apply(total),
      },
    ]);
  }, [firstPaymentMethod]);

  if (data && !data.isActive && modeAs != 'preview') {
    return (
      <Alert
        message={
          <span>
            <div className="flex items-center justify-center my-4">
              <img className="" src={getCdnUrl(data?.companyLogo, logo)}></img>
            </div>
            <p className="font-bold mb-3">Checkout indisponível!</p>
            <p className="mb-1">
              Geralmente isso ocorre quando o checkout foi desabilitado ou está fora do período de vendas.
            </p>
            {data?.companyEmail && data?.companyPhone && (
              <p className="mb-5">
                Para mais informações entre em contato com <strong>{data?.companyEmail}</strong> ou no telefone{' '}
                <strong>{data?.companyPhone}</strong>
              </p>
            )}
          </span>
        }
        type="error"
        showIcon
      />
    );
  }

  if (isError && axios.isAxiosError(error)) {
    const code = error.response?.data?.code;

    if (code == 'PAID') {
      return <Alert message="Pedido já foi pago" type="success" showIcon />;
    }

    if (code == 'CANCELED') {
      return <Alert message="Pedido cancelado" type="error" showIcon />;
    }

    if (code == 'NOT_FOUND') {
      return (
        <Alert
          message={
            <span>
              <div className="flex items-center justify-center my-4">
                <img width={150} className="" src={getCdnUrl(data?.companyLogo, logo)}></img>
              </div>
              <p className="font-bold mb-3">Checkout não encontrado!</p>
              <p className="mb-1">Geralmente isso ocorre quando o link do checkout está incorreto.</p>
              {data?.companyEmail && data?.companyPhone && (
                <p className="mb-5">
                  Para mais informações entre em contato com <strong>{data?.companyEmail}</strong> ou no telefone{' '}
                  <strong>{formatPhone(data?.companyPhone)}</strong>
                </p>
              )}
            </span>
          }
          type="error"
          showIcon
        />
      );
    }

    return <Alert message="Erro ao carregar checkout" type="error" showIcon />;
  }

  const firstItems = items.filter((item) => ['main', 'cart'].includes(item.type));
  const firstItem = firstItems[0];
  const isRecurrence =
    firstItem?.billingType === 'recurrence' &&
    (!firstItem?.recurrenceChargesQuantity || firstItem?.recurrenceChargesQuantity > 1);

  if (isLoading || !data || !firstItem) {
    return <Spin tip="Carregando..." fullscreen size="large"></Spin>;
  }

  const hasSomePaymentMethod = paymentMethods.length > 0;

  const buildInitialValues = () => {
    const totalValue = handleGetTotal() / 100.0;

    const hasOcb = !!localStorage.getItem(`ocb-${data!.teamId}`);
    if (hasOcb && !['preview', 'transaction'].includes(modeAs)) {
      const ocb = JSON.parse(atob(localStorage.getItem(`ocb-${data!.teamId}`)!));
      const validPayments = ocb.payments.filter((payment: any) => paymentMethods.includes(payment.paymentMethod));

      const totalPerPayment = totalValue / (validPayments.length ?? 1);

      const result = {
        customer: {
          id: ocb.customer.id,
          name: ocb.customer.name,
        },
        payments: validPayments.map((payment: any) => {
          return {
            paymentMethod: 'credit_card',
            value: Number(totalPerPayment.toFixed(2)),
            creditCard: {
              id: payment.id,
            },
          };
        }),
      };

      if (result.payments.length == 0) {
        result.payments = [
          {
            paymentMethod: firstPaymentMethod,
            value: moneyMask.apply(totalValue),
          },
        ];
      }

      return result;
    }

    let customer = {
      name: searchParams?.get('name') ?? searchParams?.get('nome') ?? '',
      email: searchParams?.get('email') ?? '',
      document: searchParams?.get('document') ?? searchParams?.get('documento') ?? searchParams?.get('doc') ?? '',
      phone: searchParams?.get('phone') ?? searchParams?.get('telefone') ?? searchParams?.get('cel') ?? '',
      ddi: 'BR55',
      country: 'BR',
    };

    if (modeAs == 'transaction' && data?.customer) {
      customer = {
        id: data.customer.id,
        name: data.customer.name,
      } as any;
    }

    return {
      customer,
      payments: [
        {
          paymentMethod: firstPaymentMethod,
          value: moneyMask.apply(totalValue),
        },
      ],
    };
  };

  const handleDisableOCB = () => {
    localStorage.removeItem(`ocb-${data!.teamId}`);
    setOcbData(undefined);

    form.setFieldsValue(buildInitialValues());
  };

  const handleSetForeigner = (enabled: boolean) => {
    form.setFieldsValue({
      customer: {
        ...form.getFieldValue('customer'),
        document: undefined,
        address: undefined,
      },
    });

    form.setFieldsValue({
      payments: [
        {
          paymentMethod: firstPaymentMethod,
          value: moneyMask.apply(handleGetTotal() / 100.0),
        },
      ],
    });

    if (enabled) {
      setPaymentMethods(['credit_card']);
    }

    if (!enabled) {
      setPaymentMethods([
        ...(data?.allowCreditCard ? ['credit_card'] : []),
        ...(data?.allowPix ? ['pix'] : []),
        ...(data?.allowBoleto ? ['boleto'] : []),
      ]);
    }

    setIsForeigner(enabled);
  };

  const inResumeMode = isOCB || (modeAs == 'transaction' && data?.customer?.id);
  const canChangeForeigner = !isOCB && !data?.customer?.id && paymentMethods.includes('credit_card');

  return (
    <FormProvider
      value={{
        checkout: data!,
        form,
        isPaying: isPending,
        items,
        modeAs,
        addItems: handleAddItems,
        removeItems: handleRemoveItems,
        getTotal: handleGetTotal,
        isCached,
        isOCB,
        ocbData,
        disableOCB: handleDisableOCB,
        isRecurrence,
        firstItem,
        isForeigner,
        setForeignerMode: handleSetForeigner,
        canChangeForeigner,
        paymentMethods,
        setPaymentMethods,
      }}
    >
      <Row
        justify="center"
        style={{
          width: '100%',
        }}
      >
        <PayingModal />
        <Col xs={24} sm={24} md={10} lg={10} xl={10} xxl={10} className="bg-white rounded-lg">
          <Modal
            closable={false}
            open={modalError}
            title="Não foi possível concluir sua compra!"
            footer={[
              <Button className="rounded-md w-20" type="default" size="large" onClick={() => setModalError(false)}>
                OK
              </Button>,
            ]}
          >
            <p className="my-3">Abaixo alguns possíveis motivos...</p>

            <Collapse
              collapsible="header"
              defaultActiveKey={['1']}
              ghost
              items={[
                {
                  key: '1',
                  label: <p className="font-bold">Dados incorretos</p>,
                  children: (
                    <p>
                      Acontece quando as informações do cartão não foram preenchidas corretamente. Verifique desde o
                      nome, passando pelo número do cartão, data de validade até o código de segurança.
                    </p>
                  ),
                },
              ]}
            />
            <Collapse
              collapsible="header"
              ghost
              items={[
                {
                  key: '2',
                  label: <p className="font-bold">Limite insuficiente</p>,
                  children: (
                    <p>
                      Isso ocorre quando o cartão selecionado não possui limite suficiente para que a compra seja feita.
                      Confira com a operadora do cartão se é possível aumentar o limite ou utilize outra forma de
                      pagamento.
                    </p>
                  ),
                },
              ]}
            />
            <Collapse
              collapsible="header"
              ghost
              items={[
                {
                  key: '3',
                  label: <p className="font-bold">Compra suspeita</p>,
                  children: (
                    <p>
                      Acontece em alguns casos quando a própria operadora do cartão classifica a compra como uma
                      possível fraude. Costuma ocorrer quando uma compra fora do padrão, como por exemplo, uma transação
                      de valor muito alto.
                    </p>
                  ),
                },
              ]}
            />

            {data?.companyEmail && data?.companyPhone && (
              <p className="mt-5">
                Para mais informações entre em contato com <strong>{data?.companyEmail}</strong> ou no telefone{' '}
                <strong>{formatPhone(data?.companyPhone)}</strong>
              </p>
            )}
          </Modal>

          <Modal
            title={<p style={{ fontWeight: 'bold', fontSize: '24px', marginBottom: 20 }}>Termos de Compra</p>}
            open={isModalOpen}
            onCancel={handleCancel}
            style={{ minWidth: 320, maxWidth: 1200 }}
            width="100%"
            footer={[
              <Button className="rounded-md w-20" type="default" size="large" onClick={() => setIsModalOpen(false)}>
                OK
              </Button>,
            ]}
          >
            {data?.terms?.value ? (
              <>
                {data?.terms?.type == 'text' && <span dangerouslySetInnerHTML={{ __html: data?.terms?.value }} />}
                {(data?.terms?.type == 'url' || data?.terms?.type == 'document') && (
                  <iframe
                    className="w-full"
                    style={{
                      height: '60vh',
                    }}
                    src={getCdnUrl(data?.terms?.value)}
                  />
                )}
              </>
            ) : (
              <>
                <Terms
                  companyName={data?.companyName}
                  companyEmail={data?.companyEmail}
                  productName={data?.items[0]?.description}
                />
              </>
            )}
          </Modal>

          <LanguageSelector />

          <Row>
            <Col span={24}>
              <Divider className="mt-0 mb-1" />
            </Col>
          </Row>

          {data.isTest && modeAs != 'preview' && <TestAlert />}

          <Header />

          <Row>
            <Col span={24}>
              <FormAntd
                className={className}
                form={form}
                scrollToFirstError
                layout="vertical"
                initialValues={buildInitialValues()}
                onFinish={onFinish}
              >
                {!inResumeMode && <BasicInfo name={['customer']} />}
                {inResumeMode && <BasicInfoResumed name={['customer']} />}

                {data?.askAddress && !inResumeMode && <Address name={['customer', 'address']} />}

                {data?.allowAddPayment && hasSomePaymentMethod && <Payment />}

                <OrderBump />

                <Row className="pt-4">
                  <Col>
                    <Checkbox checked={isSaveOCB} onChange={onChangeSaveOCB}>
                      Usar esses dados nas próximas compras
                    </Checkbox>
                  </Col>
                </Row>

                <Row className="pt-4">
                  <Col>
                    <Checkbox onChange={onChange}>
                      Concordo que li, e compreendi os
                      <Link className="ml-1" onClick={showModal}>
                        Termos de Compra
                      </Link>
                    </Checkbox>
                  </Col>
                </Row>

                {data?.allowAddPayment && hasSomePaymentMethod && (
                  <Row className="pt-5">
                    <Col span={24}>
                      <Button
                        htmlType="submit"
                        type="primary"
                        block
                        icon={<ShoppingCartOutlined style={{ fontSize: 20 }} />}
                        size="large"
                        disabled={isPending || !enablePay}
                        loading={isPending}
                      >
                        COMPRAR AGORA
                      </Button>
                    </Col>
                  </Row>
                )}
              </FormAntd>
            </Col>
          </Row>
          <Footer />
        </Col>
        {firstItem?.details && (
          <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}>
            <div className="bg-white rounded-lg p-3 ml-3">
              <p className="font-bold mt-1">DETALHES</p>
              <Divider className="mt-3 mb-3" />
              <span className="text-sm text-gray-900" dangerouslySetInnerHTML={{ __html: firstItem?.details }} />
              <Divider className="mt-4 mb-3" />
              {data?.companyName && (
                <p>
                  Oferecido por <span className="font-bold">{data?.companyName}</span>
                </p>
              )}
              {data?.companyEmail && <p>{data?.companyEmail}</p>}
              {data?.companyPhone && <p>{formatPhone(data?.companyPhone)}</p>}
            </div>
          </Col>
        )}
      </Row>
    </FormProvider>
  );
});

export default Form;
